









import Vue from 'vue';
export default Vue.extend({
  name: 'FormFieldComponent',
  props: {
    headline: String,
  },
});
