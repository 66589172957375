



















import Vue from 'vue';

import { pokemonName } from '@/components/filters';

import CheckboxComponent from './checkbox.component.vue';
import FormFieldComponent from './form-field.component.vue';

export default Vue.extend({
  components: {
    'v-checkbox': CheckboxComponent,
    'v-form-field': FormFieldComponent,
  },
  props: {
    filters: {
      required: true,
      type: Map,
    },
    value: {
      required: false,
      type: Map,
      default: () => new Map<string, string[]>(),
    },
  },
  computed: {
    filtersName(): string[] {
      const filters = this.filters as Map<string, string[]>;
      return Array.from(filters.keys());
    },
  },
  methods: {
    onValueChange(filterName: string, filter: string): void {
      const changeMap = new Map(this.$props.value) as Map<string, string[]>;

      if (
        changeMap.has(filterName) &&
        changeMap.get(filterName)!.includes(filter)
      ) {
        const oldFilters = changeMap.get(filterName);

        oldFilters!.length === 1
          ? changeMap.delete(filterName)
          : changeMap.set(
              filterName,
              oldFilters!.filter((_filter) => _filter !== filter)
            );
      } else {
        const oldFilters = changeMap?.get(filterName);
        changeMap.set(
          filterName,
          oldFilters ? [...oldFilters, filter] : [filter]
        );
      }

      this.$emit('input', changeMap);
    },
    isActive(filterName: string, filter: string): boolean {
      const activeFilters = this.$props.value as Map<string, string[]>;

      return (
        activeFilters.has(filterName) &&
        !!activeFilters.get(filterName)?.includes(filter)
      );
    },
  },
  filters: {
    pokemonName,
  },
});
