



















import Vue from 'vue';
import { Route } from 'vue-router';

import InputComponent from '@/components/input.component.vue';
import IconButtonComponent from '@/components/icon-button.component.vue';
import ArrowLeftIcon from '@/components/icons/arrow-left.icon.vue';
import ArrowRightIcon from '@/components/icons/arrow-right.icon.vue';

export default Vue.extend({
  components: {
    'v-input': InputComponent,
    'v-icon-button': IconButtonComponent,
    'v-arrow-right-icon': ArrowRightIcon,
    'v-arrow-left-icon': ArrowLeftIcon,
  },
  methods: {
    onPageChange(newPage: number | string): void {
      const numericPage = +newPage || 1;
      const validPage = Math.min(Math.max(numericPage, 1), this.$props.count);

      this.$emit('pageChange', validPage);
    },
  },
  props: {
    count: {
      required: true,
      type: Number,
    },
    current: {
      required: true,
      type: Number,
    },
  },
});
