















import Vue from 'vue';

import { getPokemonIdFromUrl, calculatePokemonSpriteWidth } from '@/helpers';
import { PokemonsReferencePage } from '@/models';
import { pokemonName } from '@/components/filters';

type PokemonExtendedReferenceModel = PokemonsReferencePage.PokemonExtendedReferenceModel;

export default Vue.extend({
  data() {
    return {
      maxWidth: '1rem',
    };
  },
  props: {
    extendedPokemon: {
      required: true,
      type: Object,
    },
  },
  mounted(): void {
    this.calculateMaxWidth();
    addEventListener('resize', this.calculateMaxWidth.bind(this));
  },
  methods: {
    calculateMaxWidth(): void {
      this.maxWidth = `${calculatePokemonSpriteWidth(this.$el, 96) / 16}rem`;
    },
  },
  beforeDestroy(): void {
    removeEventListener('resize', this.calculateMaxWidth);
  },
  computed: {
    pokemon(): PokemonExtendedReferenceModel {
      return this.extendedPokemon;
    },
    id(): string {
      return getPokemonIdFromUrl(this.pokemon.url);
    },
    fontSize(): string {
      return `${2 / Math.max(this.id.length, 2)}em`;
    },
  },
  filters: {
    pokemonName,
  },
});
