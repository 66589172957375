














import Vue from 'vue';
export default Vue.extend({
  name: 'CheckboxComponent',
  props: {
    name: String,
    value: {
      type: Boolean,
      default: false,
    },
  },
});
